import produce from "immer";

const initialState = {
  histories: ["/fab7ad73dc924c59b9855c570b7f4e7f"],
  defaultId: "/fab7ad73dc924c59b9855c570b7f4e7f", 
}

const history = {
  state: initialState,
  reducers: {
    setHistories(state, payload) {
      return produce(state, draft => {
        draft.histories = payload
      })
    }
  },
  effects: dispatch => ({
    async pushHistory(payload, state) {
      const tempHistories = Object.assign([], state.history.histories);
      tempHistories.push(payload);
      await dispatch.history.setHistories(tempHistories);
    },
    async popHistory(_, state) {
      const tempHistories = Object.assign([], state.history.histories); 
      if (tempHistories.length > 1){
        tempHistories.splice(-1);
        await dispatch.history.setHistories(tempHistories);
        return tempHistories[tempHistories.length - 1];
      }
      return state.history.defaultId;
    }
  })
}

export default history
