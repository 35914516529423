import { init } from "@rematch/core";
import history from "./history";

export const createStore = () => {
  const store = init({
    models: {
      history
    }
  });
  return store
}